.warranty-info-list li {
  margin-top: 0.8rem;
}

.warranty-info-list .MuiListItemText-primary {
  font-size: 0.8rem;
}

.warranty-info-list .MuiListItemText-secondary {
  font-size: 1rem;
}
