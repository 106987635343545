/* Fixes theming on highlighted today date */

.flatpickr-day.today {
  background: none !important;
  color: inherit !important;
  border: 1px #1a73e8 !important;
}

.flatpickr-day.today.selected {
  background: #1a73e8 !important;
  color: white !important;
  border: none !important;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  background: rgba(26, 115, 232, 0.28);
  color: white !important;
  border: none !important;
}
